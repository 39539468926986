import React, { Fragment, useState, useEffect } from "react";
import { graphql } from "gatsby";

import PageLayout from "../components/page-layout";
import Star from "../components/star";

import * as style from "./company.module.css";

async function fetchCompany(name) {
  const response = await fetch(`/api/v1/companies/${name}`);
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
}

function StarRating({ value }) {
  const stars = [];
  for (let i = 0; i < value; i++) {
    stars.push(<Star key={i} className={style.star} />);
  }

  return <span className={style.stars}>{stars}</span>;
}

function Row({ children }) {
  return <div className={style.row}>{children}</div>;
}

const LANGUAGES = {
  de: "German",
  en: "English",
  other: "Other",
};

function Card({
  position,
  minority,
  overall,
  overall_numeric,
  training,
  training_numeric,
  colleagues,
  colleagues_numeric,
  microaggressions,
  microaggressions_numeric,
  "spoken-language": spokenLanguage,
}) {
  return (
    <article className={style.card}>
      <h2>{position}</h2>
      <h3>{minority}</h3>
      <Row>
        <span>Overall</span>
        <StarRating value={overall_numeric} />
        <span>{overall}</span>
      </Row>
      <Row>
        <span>Colleagues</span>
        <StarRating value={colleagues_numeric} />
        <span>{colleagues}</span>
      </Row>
      <Row>
        <span>Microaggression level</span>
        <StarRating value={microaggressions_numeric} />
        <span>{microaggressions}</span>
      </Row>
      <Row>
        <span>Diversity Training</span>
        <StarRating value={training_numeric} />
        <span>{training}</span>
      </Row>
      <Row>
        <span>Mostly spoken language</span>
        <span>{LANGUAGES[spokenLanguage]}</span>
      </Row>
    </article>
  );
}

export default function CompanyPage({ data, location }) {
  const [company, setCompany] = useState();

  const { search } = location;

  const match = search.match(/name=([^&]*)/);
  const name = match && match[1];

  useEffect(() => {
    fetchCompany(name).then(setCompany);
  }, [name]);

  return (
    <PageLayout>
      <section className={style.section}>
        {company && (
          <Fragment>
            <h1>{company.name}</h1>

            <Row>
              <span>Overall</span>
              <StarRating value={company.summary.overall_numeric} />
            </Row>
            <Row>
              <span>Colleagues</span>
              <StarRating value={company.summary.colleagues_numeric} />
            </Row>
            <Row>
              <span>Microaggression Level</span>
              <StarRating value={company.summary.microaggressions_numeric} />
            </Row>
            <Row>
              <span>Diversity Training</span>
              <StarRating value={company.summary.training_numeric} />
            </Row>

            <h3>What people say:</h3>
            {(company.feedback || []).map((feedback, index) => (
              <Card key={index} {...feedback} />
            ))}
          </Fragment>
        )}
        {company === null && `Company "${name}" not found!`}
      </section>
    </PageLayout>
  );
}

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "bg.svg" }) {
      publicURL
    }
  }
`;
